import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Басты | Gearhead гаражы - автокөліктерге қызмет көрсету және т.б
			</title>
			<meta name={"description"} content={"Gearhead Garage-ге қош келдіңіз, бұл сіздің ең жақсы көліктерге қызмет көрсету және техникалық қызмет көрсету үшін баратын жер"} />
			<meta property={"og:title"} content={"Басты | Gearhead гаражы - автокөліктерге қызмет көрсету және т.б"} />
			<meta property={"og:description"} content={"Gearhead Garage-ге қош келдіңіз, бұл сіздің ең жақсы көліктерге қызмет көрсету және техникалық қызмет көрсету үшін баратын жер"} />
			<meta property={"og:image"} content={"https://kz-fiorinity.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kz-fiorinity.com/img/7956810.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kz-fiorinity.com/img/7956810.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kz-fiorinity.com/img/7956810.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kz-fiorinity.com/img/7956810.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kz-fiorinity.com/img/7956810.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kz-fiorinity.com/img/7956810.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7" background="--color-darkL2">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" color="--light" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Gearhead гаражы{" "}
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--light">
						Gearhead Garage-ге қош келдіңіз, бұл сіздің ең жақсы көліктерге қызмет көрсету және техникалық қызмет көрсету үшін баратын жер. Біздің гараж - бұл көлік әуесқойлары мен көлік иелері үшін ерекше күтім мен тәжірибе іздейтін киелі орын. Gearhead Garage-те біз адам мен оның машинасы арасындағы байланысты түсінеміз.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-red"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-red"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Бізбен хабарласыңы
						</Button>
					</Box>
				</Box>
				<Image
					src="https://kz-fiorinity.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Неліктен Gearhead гаражын таңдау керек?
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Әртүрлі модельдердегі тәжірибе
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Біздің білікті механиктер тобымыз классикалық сұлулығыңыз немесе соңғы спорт көлігінің иесі болсаңыз да, сараптамалық қызмет ұсынатын автомобиль үлгілерінің кең спектріне маманданған.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Кешенді қызмет көрсету ауқымы
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ағымдағы техникалық қызмет көрсетуден күрделі жөндеуге дейін біздің қызметтер сіздің көлігіңіздің ең жақсы жағдайда болуы үшін қажет нәрсенің барлығын қамтиды.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Жетілдірілген диагностикалық құралдар
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						біз тиімді және тиімді қызмет көрсетуді қамтамасыз ететін мәселелерді дәл анықтау және түзету үшін соңғы диагностикалық құралдарды пайдаланамыз.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Images-24">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				flex-wrap="wrap"
				max-height="1200px"
				max-width="1280px"
				align-content="flex-start"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-width="100%"
				lg-max-width="none"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Image
				src="https://kz-fiorinity.com/img/2.jpg"
				display="block"
				width="40%"
				lg-order="-1"
				sm-width="100%"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--headline2"
				width="35%"
				text-align="left"
				order="-1"
				lg-width="100%"
				lg-font="--headline3"
				sm-margin="16px 0px 0px 0px"
			>
				Бірегей артықшылықтар
			</Text>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--lead"
				width="20%"
				lg-width="100%"
				lg-max-width="540px"
			>
				Өнімділік жаңартулары: Көлігіңіздің өнімділігін арттырғыңыз келе ме? Біз қозғалтқышты баптау, газ шығаруды жаңарту және т.б. қоса, жаңарту қызметтерінің ауқымын ұсынамыз.
				<br />
				<br />
				Мөлдір баға: бұл жерде таңқаларлық емес. Біз барлық қызметтерге нақты, алдын ала бағаны береміз, жасырын төлемдерсіз.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://kz-fiorinity.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Редуктор гаражы - мұнда әрбір көлік келгеннен жақсырақ шығады
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://kz-fiorinity.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});